<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <el-date-picker
                  :picker-options="pickerOptions"
                  @change="fetchClients"
                  class="ml-3"
                  format="yyyy-MM-dd"
                  style="margin-right: 11px"
                  type="daterange"
                  v-model="form.date_range"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="view brd-none">
      <div class="view-body">
        <static-table
            :data="clients"
            :params="params"
            :thead="thead"
            @sortChange="onSortChange"
        />
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
    <div class="view">
      <div class="view-heading">
        <div class="view-title">
          {{ $t('system.generation') }}
        </div>
      </div>
      <div
          class="view-body"
          v-permission="['active-client-generate']"
      >
        <el-button
            :disabled="clients.length < 1"
            :loading="generatingReport.includes('docx')"
            @click.prevent.stop="generateFile('docx')"
            plain
            type="success"
        >
          {{ $t('system.doc') }}
        </el-button>
        <el-button
            :disabled="clients.length < 1"
            :loading="generatingReport.includes('pdf')"
            @click.prevent.stop="generateFile('pdf')"
            plain
            type="info"
        >
          {{ $t('system.pdf') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import StaticTable from '../../components/ABtable/StaticTable.vue';
import notification from '../../notification/notify';
import {downloadReport} from '@/helpers/files';

export default {
  name: 'ActiveClients',
  components: {
    StaticTable,
  },
  data() {
    return {
      params: {
        column: 'project_id',
        direction: 'desc',
        per_page: 10,
        page: 1,
        search_column: 'id',
        search_operator: 'equal_to',
        search_query_1: '',
        search_query_2: '',
      },
      source: 'active_clients',
      options: {
        paginateShow: false,
        fetchData: false,
      },
      thead: [
        {
          label: 'system.project_id',
          key: 'project_id',
          sort: true,
        },
        {
          label: 'system.client_name',
          key: 'project_name',
          align: 'abTable--alignLeft',
          sort: true,
          link: true,
          url: '/project/',
        },
        {
          label: 'system.last_activity',
          key: 'last_activity',
          sort: true,
        },
        {
          label: 'system.unreported',
          key: 'unreported_time_sum',
          sort: true,
        },
        {
          label: 'system.reported',
          key: 'reported_time_sum',
          sort: true,
        },
        {
          label: 'system.settled',
          key: 'settled_time_sum',
          sort: true,
        },
        {
          label: 'system.unsettled',
          key: 'unsettled_time_sum',
          sort: true,
        },
        {
          label: 'system.invoiced',
          key: 'invoiced_time_sum',
          sort: true,
        },
        {
          label: 'system.not_invoiced',
          key: 'not_invoiced_time_sum',
          sort: true,
        },
      ],
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: this.$t('date.today'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_week'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_three_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
      total: null,
      clients: [],
      form: {
        date_picker: 'between',
        date_range: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      },
      generatingReport: [],
    };
  },
  beforeMount() {
    this.fetchClients();
  },
  methods: {
    onSortChange(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchClients();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchClients();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchClients();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchClients();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchClients();
      }
    },
    sort(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      // this.fetchClients()
    },
    fetchClients() {
      axios.get('active_clients', {
        params: {
          date_picker: this.form.date_picker,
          date_start: moment(this.form.date_range[0]).format('YYYY-MM-DD'),
          date_end: moment(this.form.date_range[1]).format('YYYY-MM-DD'),
          ...this.params,
        },
      })
          .then((response) => {
            this.clients = response.data.data.map(entity => {
              const baseEntity = {
                project_id: entity.project_id,
                project_name: entity.project_name,
                last_activity: entity.last_activity,
              };
              const countSuffix = '_count';
              const timeSumSuffix = '_time_sum';
              Object.keys(entity).forEach(key => {
                if (!baseEntity.hasOwnProperty(key) && key.endsWith(timeSumSuffix)) {
                  const baseString = key.slice(0, -timeSumSuffix.length);
                  baseEntity[key] = `${entity[key] ? entity[key].slice(0, -3) : '00:00'} (${entity[baseString + countSuffix]})`;
                }
              });
              return baseEntity;
            });
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log('error', error);
            notification.notify(
                this.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    generateFile(type) {
      this.generatingReport.push(type);
      let dateStart = null;
      let dateEnd = null;
      if (this.form.date_range != null) {
        if (this.form.date_range[0] !== null) {
          dateStart = moment(this.form.date_range[0]).format('YYYY-MM-DD');
          dateEnd = moment(this.form.date_range[1]).format('YYYY-MM-DD');
        }
      }
      let url = 'active_clients/generate';
      axios.get(url, {
        responseType: 'arraybuffer',
        params: {
          type: type,
          date_picker: this.form.date_picker,
          date_start: dateStart,
          date_end: dateEnd,
          ...this.clientsParams,
        },
      })
          .then((response) => {
            downloadReport(response);
            // const filename = `Report ${moment().format('DD-MM-YYYY hh:mm:ss')}`
            // this.$store.dispatch('DownloadBlob', {response, filename})
          })
          .catch((error) => {
            notification.notify(
                this.$t('notify.error'),
                error.response.data.error,
                'error');
          }).finally(() => {
        this.generatingReport = this.generatingReport.filter(item => item !== type);
      });
    },
  },
};
</script>
